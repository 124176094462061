<template>
  <div class="wrapper">
    <div class="from">出版社：{{ resourcesInfo.resources_publishing }} |
      出版日期：{{ resourcesInfo.resources_publishing_time }}
    </div>
    <div class="title">内容简介</div>
    <div class="desc" ref="content" :class="{active: descActive}">
      {{ resourcesInfo.resources_desc }}
    </div>
    <div class="desc-more" v-show="showBtn" @click="descMoreClick">
      <div class="name" v-show="descActive">查看更多</div>
      <div class="name" v-show="!descActive">收起</div>
      <div class="icon" :class="{active: descActive}"></div>
    </div>
    <div class="bor"></div>
    <div class="title mrtb" v-show="showDirectory">目录</div>
    <div class="directory">
      <div class="item" v-for="(item, index) in resourcesInfo.resources_directory_min" :key="index"
           @click="changePage(item.page)">
        {{ item.name }}
      </div>
      <div class="bottom" v-show="showMore" @click="showPopup">
        <div class="point">...</div>
        <div class="more">
          <div class="name">查看全部</div>
          <div class="icon"></div>
        </div>
      </div>
    </div>
    <div class="bor"></div>
  </div>
</template>

<script>
export default {
  name: 'BookDetailDesc',
  components: {},
  props: {
    resourcesInfo: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  mounted () {
  },
  data () {
    return {
      showDirectory: false, // 目录标题标记
      showBtn: false, // 简介查看更多标记
      descActive: true, // 简介查看更多
      showMore: false, // 目录查看更多标记
      data: []
    }
  },
  methods: {
    descMoreClick () {
      this.descActive = !this.descActive
      this.$emit('refresh')
    },
    showPopup () {
      this.$emit('showPopup')
    },
    changePage (page) {
      this.$emit('changePage', page)
    }
  },
  watch: {
    resourcesInfo () {
      this.$nextTick(() => {
        if (this.$refs.content.scrollHeight - this.$refs.content.clientHeight > 10) {
          this.showBtn = true
        } else {
          this.showBtn = false
        }
        if (this.resourcesInfo.resources_directory_min.length) {
          this.showDirectory = true
        }
        if (this.resourcesInfo.resources_directory_min.length < this.resourcesInfo.resources_directory.length) {
          this.showMore = true
        }
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/assets/stylus/mixin"
.wrapper
  position relative
  display table
  width 750px
  height auto
  border-radius 30px 30px 0 0
  margin-top -45px
  background rgba(255, 255, 255, 1)

  .from
    display flex
    align-items center
    justify-content center
    width 690px
    height 24px
    font-weight 500
    font-size 24px
    color rgba(102, 102, 102, 1)
    padding 12px 0 12px 0
    margin 47px auto 37px auto
    background rgba(245, 245, 244, 1)

  .title
    width 650px
    height 30px
    line-height 34px
    border-left 8px solid rgba(179, 53, 58, 1)
    padding-left 22px
    font-weight bold
    font-size 30px

    &.mrtb
      margin-top 50px
      margin-bottom 32px

  .desc
    width 676px
    font-size 30px
    color #333333
    line-height 38px
    margin 30px 0 30px 30px

    &.active
      no-wrap2(6)

  .desc-more
    display flex
    justify-content center
    align-items center
    width 690px
    line-height normal
    margin 0 auto 30px auto
    font-size 26px
    font-weight 500
    color rgba(153, 153, 153, 1)

    .icon
      width 18px
      height 10px
      margin-left 12px
      bg-image('~@/assets/img/book-detail/more')
      background-size 100% 100%
      background-repeat no-repeat
      transform rotate(180deg)

      &.active
        transform rotate(0deg)

  .bor
    width 750px
    height 10px
    background rgba(245, 245, 244, 1)

  .directory
    width 690px
    height auto
    margin 0 auto

    .item
      width 690px
      height 60px
      line-height 60px
      font-size 30px
      font-weight 500
      color rgba(51, 51, 51, 1)
      border-bottom 1px solid rgba(245, 245, 244, 1)
      no-wrap()

    .bottom
      display flex
      width 690px
      height 60px
      margin-bottom 36px

      .point
        width 345px
        line-height 60px
        font-size 30px
        font-weight 500
        color rgba(51, 51, 51, 1)

      .more
        display flex
        align-items center
        width 375px
        height 60x

        .name
          width 355px
          line-height normal
          padding 17px 0 17px 0
          text-align right
          font-size 26px
          color rgba(180, 180, 180, 1)

        .icon
          width 10px
          height 18px
          margin-left 10px
          bg-image('~@/assets/img/personal/more')
          background-size 100% 100%
          background-repeat no-repeat
</style>
