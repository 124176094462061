<template>
  <div class="wrapper">
    <div class="title">
      <div class="text">猜你喜欢</div>
      <div class="icon" @click="changeResourcesList"></div>
      <div class="change" @click="changeResourcesList">换一换</div>
    </div>
    <div class="list">
      <div
        @click="bookDetail(item)"
        class="item"
        v-for="(item,index) in resourcesList" :key="index"
      >
        <div class="pic-block">
          <img class="pic" :src="item.resources_cover" alt=""/>
        </div>
        <div class="book-title">{{ item.resources_name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BookDetailLike',
  props: {
    resourcesList: {
      type: Array,
      default () {
        return []
      }
    }
  },
  methods: {
    bookDetail (item) {
      let index = this.$route.path.indexOf('/book-detail')
      let path = this.$route.path.substring(0, index)
      path += '/book-detail/' + item.resources_guid + '/' + item.resources_name
      this.$router.push(path)
    },
    changeResourcesList () {
      this.$emit('changeResourcesList')
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/assets/stylus/mixin"
.wrapper
  display table
  width 750px
  height 472px
  background #fff

  .title
    display flex
    align-items center
    width 742px
    height 30px
    border-left 8px solid rgba(179, 53, 58, 1)
    margin-top 48px
    margin-bottom 32px

    .text
      width 565px
      line-height normal
      font-weight bold
      font-size 30px
      color rgba(51, 51, 51, 1)
      margin-left 20px

    .icon
      width 22px
      height 22px
      bg-image('~@/assets/img/book-detail/change')
      background-size 100% 100%
      background-repeat no-repeat

    .change
      width 100px
      line-height normal
      font-size 26px
      font-weight 500
      color rgba(180, 180, 180, 1)
      margin-left 14px

  .list
    display flex
    justify-content space-around
    width 750px
    height 362px

    .item
      width 152px
      height 362px

      .pic-block
        width 152px
        height 222px
        border-radius 10px
        overflow hidden

        .pic
          width 100%
          height 100%

      .book-title
        width 152px
        height 60px
        line-height 34px
        font-size 26px
        font-weight 500
        color rgba(51, 51, 51, 1)
        margin-top 24px
        no-wrap2(2)
</style>
