<template>
  <div class="wrapper">
    <div class="bg-block">
      <div class="bg" :style="{backgroundImage: 'url(' + resourcesInfo.resources_cover + ')'}"></div>
    </div>
    <div class="header" v-if="resourcesInfo">
      <div class="back" @click="back">
        <div class="icon"></div>
        <div class="name">返回</div>
      </div>
      <div class="info-block">
        <div class="cover-block">
          <img class="cover" :src="resourcesInfo.resources_cover"/>
        </div>
        <div class="info">
          <div class="title">{{ resourcesInfo.resources_name }}</div>
          <div class="author">
            <div class="icon"></div>
            <div class="name">{{ resourcesInfo.resources_author }}</div>
          </div>
          <div class="from">
            来源：{{ resourcesInfo.resources_media_from }} ｜ {{ resourcesInfo.resources_read_num }}次阅读
          </div>
          <div class="label-block">
            <div
              class="label"
              v-for="(item, index) in resourcesInfo.resources_category"
              :key="index"
            >
              {{ item.category_name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BookDetailHeader',
  props: {
    resourcesInfo: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  methods: {
    back () {
      let index = this.$route.path.indexOf('/book-detail')
      let path = this.$route.path.substring(0, index)
      this.$router.push(path)
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/assets/stylus/mixin"
.wrapper
  position relative
  width 750px
  height 412px

  .bg-block
    width 750px
    height 412px
    overflow hidden

    .bg
      width 750px
      height 412px
      background-repeat no-repeat
      background-position center 0px
      background-size 750px
      -webkit-filter blur(220px)
      -moz-filter blur(220px)
      -o-filter blur(220px)
      -ms-filter blur(220px)
      filter blur(220px)
      -webkit-backdrop-filter: blur(220px)
      backdrop-filter: blur(220px)

  .header
    position absolute
    top 0
    width 100%
    height 412px

    .back
      display flex
      align-items center
      width 160px
      height 84px

      .icon
        width 16px
        height 28px
        margin-left 43px
        bg-image('~@/assets/img/reading/back')
        background-size 100% 100%
        background-repeat no-repeat

      .name
        width 100px
        line-height normal
        font-size 26px
        font-weight 500
        color rgba(51, 51, 51, 1)
        margin-left 10px

    .info-block
      display flex
      width 100%
      height 238px

      .cover-block
        width 166px
        height 228px
        border-radius 10px
        margin-left 60px
        margin-top 10px
        background rgba(255, 255, 255, .5)

        .cover
          width 100%
          height 100%
          border-radius 10px
          margin-left 10px
          margin-top -10px

      .info
        width 488px
        height 238px
        margin-left 36px

        .title
          width 488px
          height 32px
          line-height 36px
          font-size 30px
          font-weight bold
          color rgba(51, 51, 51, 1)
          margin-top 12px
          no-wrap()

        .author
          display flex
          align-items center
          width 488px
          height 26px
          margin-top 16px

          .icon
            width 20px
            height 21px
            bg-image('~@/assets/img/book-detail/author')
            background-size 100% 100%
            background-repeat no-repeat

          .name
            width 456px
            line-height normal
            margin-left 12px
            font-size 26px
            font-weight 500
            color rgba(102, 102, 102, 1)
            no-wrap()

        .from
          width 488px
          height 22px
          line-height 22px
          margin-top 76px
          font-size 22px
          color rgba(102, 102, 102, 1)

        .label-block
          display flex
          width 488px
          height 32px
          margin-top 12px

          .label
            display flex
            align-items center
            justify-content center
            line-height normal
            font-size 20px
            font-weight 500
            color rgba(255, 255, 255, 1)
            padding 6px 10px
            margin-right 16px
            border-radius 6px
            background rgba(248, 177, 63, 1)

</style>
