<template>
  <transition :appear="true" name="slide">
    <div @touchmove.prevent class="book-detail">
      <scroll
        ref="scroll"
        class="scroll"
        :data="data"
      >
        <div>
          <book-detail-header
            :resources-info="resourcesInfo"
          ></book-detail-header>
          <book-detail-desc
            v-if="resourcesInfo"
            @refresh="refresh"
            :resources-info="resourcesInfo"
            @showPopup="showPopupClick"
            @changePage="changePage"
          ></book-detail-desc>
          <book-detail-like
            v-if="resourcesList.length"
            :resources-list="resourcesList"
            @changeResourcesList="changeResourcesList"
          ></book-detail-like>
        </div>
      </scroll>
      <book-detail-bottom
        @toReading="toReading"
        :favorites-active="favoritesActive"
        @favorites="favorites"
        @share="share"
      ></book-detail-bottom>
      <!--目录弹窗-->
      <popup
        v-if="resourcesInfo"
        v-model="showPopup"
        position="left"
        duration="0.2"
        class="directory-block"
        :lock-scroll="false"
      >
        <div class="header">
          <div class="header-name">目录</div>
          <div class="chapter" @click="directorySort=!directorySort">
            <div class="chapter-text">共{{ directoryTotal }}章</div>
            <div class="chapter-icon" :class="{asc: directorySort, desc: !directorySort}"></div>
          </div>
        </div>
        <scroll ref="popupScroll" class="popup-scroll" :data="data">
          <div>
            <div class="directory-list">
              <div class="directory-item" v-for="(item, index) in directoryList" :key="index"
                   @click="changePage(item.page)">
                {{ item.name }}
              </div>
            </div>
          </div>
        </scroll>
      </popup>
      <overlay
        :show="showShare"
      >
        <share
          :show="showShare"
          :pic-url="resourcesInfo.resources_cover"
          :name="resourcesInfo.resources_name"
          :author="resourcesInfo.resources_author"
          :user-name="userName"
          :agency-guid="agencyGuid"
          :user-guid="userGuid"
          @shareClose="showShare=false"
        ></share>
      </overlay>
      <router-view></router-view>
    </div>
  </transition>
</template>

<script>
import { Toast, Popup, Overlay } from 'vant'
import Scroll from '@/components/scroll/scroll'
import BookDetailHeader from './components/Header'
import BookDetailDesc from './components/Desc'
import BookDetailLike from './components/Like'
import BookDetailBottom from './components/Bottom'
import Share from './components/Share'
import { getEbookInfo, getResourcesPageList } from '@/api/Resources'
import { mapGetters } from 'vuex'
import { getCUserFavoritesFlag, setCUserFavorites } from '@/api/Cuser'

export default {
  name: 'BookDetail',
  mounted () {
    document.title = this.$route.params.resources_name
    this._bookDetailInit()
  },
  computed: {
    ...mapGetters([
      'agencyGuid',
      'userGuid',
      'userReadCard',
      'userName'
    ])
  },
  components: {
    Scroll,
    BookDetailHeader,
    BookDetailDesc,
    BookDetailLike,
    BookDetailBottom,
    Share,
    Popup,
    Overlay
  },
  data () {
    return {
      resourcesInfo: {},
      directoryTotal: 0,
      directoryList: [],
      showPopup: false,
      directorySort: true,
      categoryGuid: [],
      resourcesList: [],
      favoritesActive: false,
      showShare: false,
      data: []
    }
  },
  methods: {
    async _bookDetailInit () {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: '加载中...'
      })
      const ebookInfoRes = await getEbookInfo({
        resources_guid: this.$route.params.resources_guid
      })
      if (ebookInfoRes.code === 200) {
        this.resourcesInfo = ebookInfoRes.data
        this.directoryTotal = ebookInfoRes.data.resources_directory.length
        if (this.directorySort) {
          this.directoryList = ebookInfoRes.data.resources_directory
        } else {
          this.directoryList = ebookInfoRes.data.resources_directory.reverse()
        }
        ebookInfoRes.data.resources_category.forEach(item => {
          this.categoryGuid.push(item.category_guid)
        })
      } else {
        return Toast.fail(ebookInfoRes.msg)
      }
      const resourcesListRes = await getResourcesPageList({
        agency_guid: this.agencyGuid,
        resources_type: [1, 5],
        page: 1,
        limit: 4,
        category_guid: this.categoryGuid,
        rand: true,
        not_in_guid: this.$route.params.resources_guid
      })
      if (resourcesListRes.code === 200) {
        this.resourcesList = resourcesListRes.data.list
      } else {
        return Toast.fail(resourcesListRes.msg)
      }
      getCUserFavoritesFlag({
        resources_guid: this.$route.params.resources_guid,
        c_user_guid: this.userGuid
      }).then(res => {
        if (res.code === 200) {
          this.favoritesActive = res.data
        }
      })
      this.$refs.scroll.scrollTo(0, 0)
      this.refresh()
      Toast.clear()
    },
    refresh () {
      setTimeout(() => {
        this.$refs.scroll.refresh()
      }, 100)
    },
    showPopupClick () {
      this.showPopup = true
    },
    changeResourcesList () {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: '加载中...'
      })
      getResourcesPageList({
        agency_guid: this.agencyGuid,
        resources_type: [1, 5],
        page: 1,
        limit: 4,
        category_guid: this.categoryGuid,
        rand: true,
        not_in_guid: this.$route.params.resources_guid
      }).then(res => {
        const { code, data, msg } = res
        if (code === 200) {
          this.resourcesList = data.list
          this.refresh()
          Toast.clear()
        } else {
          Toast.fail(msg)
        }
      })
    },
    toReading () {
      if (this.userReadCard) {
        if (this.resourcesInfo.resources_type === 1) {
          this.$router.push(this.$route.path + '/ebook-detail')
        } else if (this.resourcesInfo.resources_type === 5) {
          this.$router.push(this.$route.path + '/ebook-detail-qq')
        }
      } else {
        this.$router.push({
          name: 'Login',
          params: {
            link: this.$route.path
          }
        })
      }
    },
    changePage (page) {
      if (this.userReadCard) {
        if (this.resourcesInfo.resources_type === 1) {
          this.$router.push({
            path: this.$route.path + '/ebook-detail',
            query: {
              page
            }
          })
        } else if (this.resourcesInfo.resources_type === 5) {
          this.$router.push({
            path: this.$route.path + '/ebook-detail-qq',
            query: {
              page
            }
          })
        }
        this.showPopup = false
      } else {
        this.$router.push({
          name: 'Login',
          params: {
            link: this.$route.path
          }
        })
      }
    },
    favorites (flag) {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: '加载中...'
      })
      setCUserFavorites({
        c_user_guid: this.userGuid,
        favorites_obj: this.$route.params.resources_guid,
        favorites_type: 1,
        favorites_status: flag ? -1 : 1
      }).then(res => {
        if (res.code === 200) {
          this.favoritesActive = !flag
        }
        Toast.clear()
      })
    },
    share () {
      this.showShare = true
    }
  },
  watch: {
    directorySort () {
      this.directoryList.reverse()
    },
    $route (to, from) {
      if ((this.$route.name === 'PersonalBookDetail' || this.$route.name === 'BookDetail' || this.$route.name === 'HomeBookDetail' || this.$route.name === 'BooksDetailBookDetail') && to.path !== from.path) {
        document.title = this.$route.params.resources_name
        this._bookDetailInit()
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/assets/stylus/mixin"
.slide-enter-active, .slide-leave-active
  transition: all 0.3s

.slide-enter, .slide-leave-to
  transform: translate3d(100%, 0, 0)

.book-detail
  position absolute
  z-index 100
  top 0
  left 0
  right 0
  bottom 0
  background rgba(245, 245, 244, 1)

  .scroll
    position absolute
    top 0
    left 0
    right 0
    bottom 97px
    bottom calc(constant(safe-area-inset-bottom) + 97px)
    bottom calc(env(safe-area-inset-bottom) + 97px)
    overflow hidden

  .directory-block
    width 680px
    height 100%

    .header
      display flex
      width 620px
      height 82px
      margin-left 30px
      border-bottom 1px solid rgba(245, 245, 244, 1)

      .header-name
        width 423px
        height 38px
        margin-top 30px
        padding-left 22px
        line-height 38px
        font-size 34px
        font-weight bold
        color rgba(51, 51, 51, 1)
        border-left 8px solid rgba(179, 53, 58, 1)

      .chapter
        display flex
        width 174px
        height 26px
        margin-top 34px

        .chapter-text
          width 146px
          height 26px
          line-height 26px
          font-weight 500
          font-size 26px
          color rgba(153, 153, 153, 1)

        .chapter-icon
          width 28px
          height 20px
          margin-top 3px
          background-size 100% 100%
          background-repeat no-repeat

          &.asc
            bg-image('~@/assets/img/ebook-detail/asc')

          &.desc
            bg-image('~@/assets/img/ebook-detail/desc')

    .popup-scroll
      position absolute
      top 84px
      left 0
      right 0
      bottom 0
      overflow hidden

      .directory-list
        width 620px
        margin-left 30px

        .directory-item
          width 590px
          padding 30px 0 30px 30px
          line-height normal
          font-weight 500
          font-size 30px
          color rgba(51, 51, 51, 1)
          border-bottom 1px solid rgba(245, 245, 244, 1)

          &:last-child
            border-bottom none
</style>
